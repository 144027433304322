import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Form from 'react-bootstrap/Form';
import './App.css';

function App() {
  return (
    <Container fluid>
      <div className="topSpacer"></div>
      <Row>
        <Col md={2}>
          <Row>
            <Col>
              <Image roundedCircle className="smallCircle zoomHover" src="./project.png" alt="small cabal project logo" />
            </Col>
            <Col>
              <div className="topSpacer"></div>
              <a href="https://x.com/cabal_ct0/" className="dyn">[twitter]</a>
              <br />
              <a href="https://t.me/+WX4VDVqlo4E1MTg0" className="dyn">[telegram]</a>
            </Col>
            <Col>
              <div className="topSpacer"></div>
              <a href="https://www.dextools.io/app/en/solana/pair-explorer/GmmNyt3aQQrqnBag1BZXjL5A2zaLMiUk9WYY1CwvAuzv?t=1725314894294" className="dyn">[cabal]</a>
              <br />
              <a href="https://dexscreener.com/solana/gmmnyt3aqqrqnbag1bzxjl5a2zalmiuk9wyy1cwvauzv" className="dyn">[chart]</a>
            </Col>
          </Row>
        </Col>
        <Col md={8}>
          <Row>
            <Col>
              <img className="memeTopCenter" src="./meme-36-copy.png" alt="frog full ported 1000 sol into $cabal" />
            </Col>
            <Col>
              <img className="memeTopCenter" src="./meme-36-1.png" alt="mitch dumped 82.5 SOL of niggabutt" />
            </Col>
          </Row>
        </Col>
        <Col md={2}>
          <Row>
            <Col>
              <img className="memeTopRight zoomHover" src="./meme-37-1.png" alt="0.16 SOL cabal_victim" />
              <br />
              <a href="#" className="dyn">[view profile]</a>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          <p className="ce paddingTop20px"><a className="dyn bigFont" href="#">[shill a new coin]</a></p>
        </Col>
      </Row>
      <Row>
        <Col>
          <p className="ce paddingTop20px"><Image className="zoomHover kingOfTheHill" src="./king-of-the-hill.webp" alt="king of the hill" /></p>
        </Col>
      </Row>
      <Row>
        <Col>
          <p className="ce paddingTop20px"><Image className="zoomHover cabalPump" src="./meme-41-1-768x269.png" alt="biggest social experiment meme" /></p>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form>
            <Form.Group className="mb-3" controlId="ControlInput1">
              <Form.Control type="text" placeholder="search for KOL" />
            </Form.Group>
          </Form>
        </Col>
      </Row>
      <Row>
        <Col>
          <p className="paddingTop20px"><Image src="./sc-768x158.png" alt="control panel meme" /></p>
        </Col>
      </Row>
      <Row>
        <Col>
          <p className="ce paddingTop20px"><a href="https://x.com/blknoiz06"><Image className="memeTopCenter borderOnHover" src="./ansem.png" alt="ansem" /></a></p>
        </Col>
        <Col>
          <p className="ce paddingTop20px"><a href="https://x.com/TheMisterFrog"><Image className="borderOnHover" src="./frog-1-768x270.png" alt="frog" /></a></p>
        </Col>
        <Col>
          <p className="ce paddingTop20px"><a href="https://x.com/idrawline"><Image className="borderOnHover" src="./mitch-1-768x270.png" alt="mitch" /></a></p>
        </Col>
      </Row>
      <Row>
        <Col>
          <p className="ce paddingTop20px"><a href="https://x.com/yelotree"><Image className="borderOnHover" src="./yelo-2-768x270.png" alt="yelo" /></a></p>
        </Col>
        <Col>
          <p className="ce paddingTop20px"><a href="https://x.com/yogurt_eth"><Image className="borderOnHover" src="./yogurt-1-768x272.png" alt="yogurt" /></a></p>
        </Col>
        <Col>
          <p className="ce paddingTop20px"><a href="https://x.com/rektober"><Image className="borderOnHover" src="./rektober-1-768x272.png" alt="rektober" /></a></p>
        </Col>
      </Row>
      <Row>
        <Col>
          <p className="ce paddingTop20px"><a href="https://x.com/metaversejoji"><Image className="borderOnHover" src="./joji-768x271.png" alt="joji" /></a></p>
        </Col>
        <Col>
          <p className="ce paddingTop20px"><a href="https://x.com/973Meech"><Image className="borderOnHover" src="./meechie-768x271.png" alt="meechie" /></a></p>
        </Col>
        <Col>
          <p className="ce paddingTop20px"><a href="https://x.com/Yennii56"><Image className="borderOnHover" src="./yenni-1-768x271.png" alt="yenni" /></a></p>
        </Col>
      </Row>
      <Row>
        <Col>
          <p className="ce paddingTop20px"><a href="https://x.com/midasmulligann"><Image className="borderOnHover" src="./beaver-1-768x270.png" alt="beaver" /></a></p>
        </Col>
        <Col>
        </Col>
        <Col>
        </Col>
      </Row>
      <Row>
        <Col>
          <div class="ticker-tape-container">
            <div class="ticker-tape">
              <span>[cabal]</span>
              <span>[cabal]</span>
              <span>[cabal]</span>
              <span>[cabal]</span>
              <span>[cabal]</span>
              <span>[cabal]</span>
              <span>[cabal]</span>
              <span>[cabal]</span>
            </div>
            <div class="ticker-tape" aria-hidden="true">
              <span>[cabal]</span>
              <span>[cabal]</span>
              <span>[cabal]</span>
              <span>[cabal]</span>
              <span>[cabal]</span>
              <span>[cabal]</span>
              <span>[cabal]</span>
              <span>[cabal]</span>
            </div>
          </div>
        </Col>
      </Row>
      <div className="topSpacer"></div>
      <div className="topSpacer"></div>
      <div className="topSpacer"></div>
      <Row>
        <Col>
          <p className="ce"><a className="dyn bigFont" href="#">[the $cabal family album]</a></p>
        </Col>
      </Row>
      <Row>
        <Col>
          <Image src="./HODL-23-1-768x768.png" alt="mcdonalds with the bois" />
        </Col>
        <Col>
          <Image src="./HODL-24-768x768.png" alt="cabal fc winning the solana cup" />
        </Col>
      </Row>
      <Row>
        <Col>
          <Image src="./HODL-25-1-768x768.png" alt="suviving the august trenches" />
        </Col>
        <Col>
          <Image src="./HODL-26-2-768x768.png" alt="ansem and his dawg yogurt" />
        </Col>
      </Row>
      <div className="topSpacer"></div>
      <div className="topSpacer"></div>
      <div className="topSpacer"></div>
      <Row>
        <Col>
          <p className="ce"><a className="dyn bigFont" href="#">[memes]</a></p>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="topSpacer"></div>
          <p className="ce max400img"><Image src="./nb-coded-768x610.png" alt="niggabutt coded" /></p>
          <div className="topSpacer"></div>
        </Col>
        <Col>
          <div className="topSpacer"></div>
          <p className="ce max400img"><Image src="./shill-and-sell-768x768.png" alt="shill and sell" /></p>
          <div className="topSpacer"></div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="topSpacer"></div>
          <p className="ce max400img"><Image src="./BOOK-OF-RUGS-768x768.png" alt="retail" /></p>
          <div className="topSpacer"></div>
        </Col>
        <Col>
          <div className="topSpacer"></div>
          <p className="ce max600img"><Image src="./judges-768x506.png" alt="cabal judging shitters" /></p>
          <div className="topSpacer"></div>
        </Col>
      </Row>

    </Container>
  );
}

export default App;
